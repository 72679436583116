<script setup lang="ts">
import { useUserEnergyStore, useUserTapPowerStore } from '@/stores/userAbilities'
import { useUserBalanceStore } from '@/stores/userBalance'
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import ListItem from '../components/common/ListItem.vue'
import BalanceWidget from './common/BalanceWidget.vue'
import BtnPrimary from './common/BtnPrimary.vue'
import Container from './common/Container.vue'
import ExpansionPanel from './common/ExpansionPanel.vue'
import HowItWorks from './HowItWorks.vue'
import IconBoltRounded from './icons/IconBoltRounded.vue'
import IconBoltSolidRounded from './icons/IconBoltSolidRounded.vue'

const { t } = useI18n()

const userBalanceStore = useUserBalanceStore()
const { getBalance } = userBalanceStore
const { balance } = storeToRefs(userBalanceStore)

const userEnergyStore = useUserEnergyStore()
const { getAvailableEnergyBoost, upgradeEnergyBoost } = userEnergyStore
const { energyBoost } = storeToRefs(userEnergyStore)

const energyBoostAvailable = computed(() => {
  return energyBoost.value && balance.value >= energyBoost.value.price
})

const { getAvailableTapPowerBoost, upgradeTapPowerBoost } = useUserTapPowerStore()
const { tapPowerBoost } = storeToRefs(useUserTapPowerStore())

const tapPowerBoostAvailable = computed(() => {
  return tapPowerBoost.value && balance.value >= tapPowerBoost.value.price
})

onMounted(async () => {
  try {
    await Promise.all([getAvailableEnergyBoost(), getAvailableTapPowerBoost(), getBalance()])
  } catch (error) {
    //
  }
})

const upgradeEnergy = async () => {
  if (!energyBoostAvailable.value) return

  await upgradeEnergyBoost()
  await getBalance()
}
const upgradeTapPower = async () => {
  if (!tapPowerBoostAvailable.value) return

  await upgradeTapPowerBoost()
  await getBalance()
}
</script>

<template>
  <Container>
    <BalanceWidget :balance="balance" class="mt-15" />

    <div class="list-container mt-15">
      <ListItem
        :title="
          energyBoost
            ? t('common.energyLimit') + ': ' + `${energyBoost.energy_limit}`
            : t('boostTab.maxEnergyLevelCaptured')
        "
        :price="energyBoost?.price || ''"
        :disabled="!energyBoostAvailable"
        @click.prevent="upgradeEnergy"
      >
        <template #prepend>
          <IconBoltRounded style="width: 48px; height: 48px; max-width: 48px" />
        </template>
        <template #append>
          <BtnPrimary :paddingX="32" class="boost-btn">{{ t('boostTab.boost') }}</BtnPrimary>
        </template>
      </ListItem>

      <ListItem
        :title="
          tapPowerBoost
            ? t('common.tapPower') + ': ' + `${tapPowerBoost.tap_points}`
            : t('boostTab.maxTapPowerReached')
        "
        :price="tapPowerBoost?.price || ''"
        :disabled="!tapPowerBoostAvailable"
        @click.prevent="upgradeTapPower"
      >
        <template #prepend>
          <IconBoltSolidRounded style="width: 48px; height: 48px; max-width: 48px" />
        </template>
        <template #append>
          <BtnPrimary :paddingX="32" class="boost-btn">{{ t('boostTab.boost') }}</BtnPrimary>
        </template>
      </ListItem>
    </div>

    <ExpansionPanel class="mt-8" more-btn-text="common.howItWorks">
      <HowItWorks />
    </ExpansionPanel>
  </Container>
</template>

<style scoped>
.balance {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.balance-icon {
  width: 40px;
  height: 40px;
}
.boost-btn {
  display: none;
}
@media (min-width: 390px) {
  .boost-btn {
    display: flex;
  }
}
</style>
