<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import IconBalance from '../icons/IconBalance.vue'

interface Props {
  passiveIncome: number
  energy: string
}

defineProps<Props>()

const { t } = useI18n()
</script>

<template>
  <div class="flex justify-between items-center info">
    <div class="flex-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.4"
          d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44712 2 1.96997 6.47715 1.96997 12C1.96997 17.5228 6.44712 22 11.97 22Z"
          fill="#577B34"
        />
        <path
          d="M15.25 11.3H13.55V7.33999C13.55 6.41999 13.05 6.22999 12.44 6.91999L12 7.41999L8.28003 11.65C7.77003 12.23 7.98003 12.7 8.75003 12.7H10.45V16.66C10.45 17.58 10.95 17.77 11.56 17.08L12 16.58L15.72 12.35C16.23 11.77 16.02 11.3 15.25 11.3Z"
          fill="#68913E"
        />
      </svg>
      <div class="income-text">
        {{ energy }}
      </div>
    </div>
    <div class="flex-icon">
      <IconBalance class="info-drop" style="width: 28px; height: 28px" />
      <div class="income-text">+{{ `${passiveIncome}/${t('common.hr')}` }}</div>
    </div>
  </div>
</template>

<style scoped>
.flex-icon {
  display: flex;
  align-items: center;
  gap: 4px;
}
.income-text {
  color: var(--white);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
}
</style>
