<template>
  <img src="/img/icons/check.webp" alt="" />
  <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="currentColor"
  >
    <path
      d="M8.50008 1.91669C4.59716 1.91669 1.41675 5.0971 1.41675 9.00002C1.41675 12.9029 4.59716 16.0834 8.50008 16.0834C12.403 16.0834 15.5834 12.9029 15.5834 9.00002C15.5834 5.0971 12.403 1.91669 8.50008 1.91669ZM11.8859 7.37085L7.86966 11.3871C7.7705 11.4863 7.63591 11.5429 7.49425 11.5429C7.35258 11.5429 7.218 11.4863 7.11883 11.3871L5.11425 9.38252C4.90883 9.1771 4.90883 8.8371 5.11425 8.63169C5.31966 8.42627 5.65966 8.42627 5.86508 8.63169L7.49425 10.2609L11.1351 6.62002C11.3405 6.4146 11.6805 6.4146 11.8859 6.62002C12.0913 6.82544 12.0913 7.15835 11.8859 7.37085Z"
    />
  </svg> -->
</template>
