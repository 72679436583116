<script setup lang="ts">
import useReferral, { type Referral } from '@/application/services/useReferral'
import { amountFormat } from '@/utils/amountFormat'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BonusesList from '../BonusesList.vue'
import Avatar from '../common/Avatar.vue'
import ExpansionPanel from '../common/ExpansionPanel.vue'
import ListItem from '../common/ListItem.vue'
import Loader from '../common/Loader.vue'
import IconBalance from '../icons/IconBalance.vue'
import IconBoltRounded from '../icons/IconBoltRounded.vue'
import IconBoltSolidRounded from '../icons/IconBoltSolidRounded.vue'

const props = defineProps<{
  scrollRef: any
}>()

defineEmits(['invite-friend'])

const { t } = useI18n()

const { referrals, getReferrals } = useReferral()

// const showBonuses = ref(false)
const refreshingReferrals = ref(false)
const scrollComponent = ref(null)
const fetchingMoreReferrals = ref(false)
const page = ref(1)
const perPage = ref(20)
const paginatedReferrals = ref<Referral[]>([])

const friendsCount = computed(() => referrals.value.length || 0)
const lastPage = computed(() => Math.ceil(referrals.value.length / perPage.value))

const gifts = [
  {
    id: 1,
    title: 'inviteTab.gifts.inviteAFriend.title',
    reward: 5000,
    subtitle: 'inviteTab.gifts.inviteAFriend.subtitle'
  },
  {
    id: 2,
    title: 'inviteTab.gifts.inviteAFriendWithTG.title',
    reward: 25000,
    subtitle: 'inviteTab.gifts.inviteAFriendWithTG.subtitle'
  }
]

const refreshReferrals = async () => {
  if (refreshingReferrals.value) return

  refreshingReferrals.value = true
  await getReferrals()
  paginatedReferrals.value = referrals.value.slice(0, perPage.value)
  refreshingReferrals.value = false
}

const handleScroll = () => {
  const element = scrollComponent.value as any

  if (lastPage.value <= page.value) return
  if (element && element.getBoundingClientRect().bottom < window.innerHeight) {
    if (!fetchingMoreReferrals.value) {
      fetchingMoreReferrals.value = true
      page.value += 1
      const start = perPage.value * (page.value - 1)
      const end = perPage.value * page.value
      const newReferrals = referrals.value.slice(start, end)

      paginatedReferrals.value.push(...newReferrals)
      fetchingMoreReferrals.value = false
    }
  }
}

onMounted(async () => {
  try {
    await refreshReferrals()
  } catch (error) {
    //
  }
  if (props.scrollRef) props.scrollRef.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  if (props.scrollRef) props.scrollRef.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div class="referral-container">
    <div class="ma-auto invite-title">
      <h2 class="page-title">{{ t('inviteTab.moreReferrals') }}!</h2>
      <h3 class="page-sub-title mt-5">{{ t('inviteTab.inviteYourFriends') }}</h3>
    </div>

    <div class="list-container">
      <ListItem
        v-for="gift in gifts"
        :key="gift.id"
        :title="t(gift.title)"
        :price="gift.reward"
        price-with-plus
        @click="$emit('invite-friend')"
      >
        <!-- :subtitle="t(gift.subtitle)" -->
        <template #prepend>
          <IconBoltRounded
            v-if="gift.id === 1"
            style="width: 48px; height: 48px; max-width: 48px"
          />
          <IconBoltSolidRounded v-else style="width: 48px; height: 48px; max-width: 48px" />
        </template>
        <template #append>
          <!-- <BtnPrimary :paddingX="32" @click="$emit('invite-friend')">
            {{ t('inviteTab.gifts.inviteAFriend.invite') }}
          </BtnPrimary> -->
        </template>
      </ListItem>
    </div>

    <ExpansionPanel class="mt-8" more-btn-text="inviteTab.moreBonuses" :show-less-btn="false">
      <BonusesList />
    </ExpansionPanel>

    <!-- friends list  -->
    <h2 class="referrals">
      <div class="referrals-title">{{ t('inviteTab.listOfYourFriends') }}</div>
      <div class="flex items-center gap-4 friends-count">
        {{ friendsCount }}
      </div>
    </h2>

    <div v-if="refreshingReferrals" class="flex justify-center">
      <Loader />
    </div>
    <template v-else>
      <div v-if="referrals.length > 0" class="list-container">
        <ListItem
          v-for="referral in paginatedReferrals"
          :key="referral.id"
          :title="referral.name"
          subtitle="Bronze"
          :price="amountFormat(referral.balance)"
        >
          <template #prepend>
            <Avatar :avatar="referral.profile_image_url" :name="referral.name" :size="40" />
          </template>
          <template #append>
            <div class="flex items-center gap-1 balance-text">
              <IconBalance style="width: 26px; height: 26px" />
              +{{ amountFormat(referral.profit) }}
            </div>
          </template>
        </ListItem>
      </div>
      <div v-else>
        <ListItem> {{ t('inviteTab.noReferralsYet') }}</ListItem>
      </div>

      <div v-if="fetchingMoreReferrals" class="flex justify-center">
        <Loader />
      </div>
      <div class="" ref="scrollComponent"></div>
    </template>
  </div>
</template>

<style scoped>
.referral-container {
  padding-bottom: 90px;
}
.more-bonuses {
  margin-top: 20px;
}
.more {
  text-align: center;
  color: #a7a0f4;
  /* color: #2e1edd;÷var(--vt-c-blue); */
}

.referrals {
  margin-top: 46px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.referrals-title {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
}

ul {
  list-style: none;
}
li {
  margin: 10px 0;
}
.friends-count {
  color: rgba(243, 255, 233, 0.6);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
}
.balance-text {
  color: var(--primary, #b2ff6b);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
}
.invite-title {
  max-width: 95%;
}
@media (min-width: 430px) {
  .invite-title {
    max-width: 70%;
  }
}
</style>
