<script setup lang="ts">
import { amountFormat } from '@/utils/amountFormat'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionBtn from '../common/ActionBtn.vue'
import BtnPrimary from '../common/BtnPrimary.vue'
import Container from '../common/Container.vue'
import DialogModal from '../common/DialogModal.vue'
import EventTimer from '../common/EventTimer.vue'
import ExpansionPanel from '../common/ExpansionPanel.vue'
import InfoBanner from '../common/InfoBanner.vue'
import ThreeRowsCard from '../common/ThreeRowsCard.vue'
import IconDrop from '../icons/IconDrop.vue'
import IconStar from '../icons/IconStar.vue'
import IconTicketStar from '../icons/IconTicketStar.vue'
import FreeTicket from './FreeTicket.vue'
import HowRaffleWorks from './HowRaffleWorks.vue'
import RaffleAmount from './RaffleAmount.vue'

import SpecialOfferBanner from '../common/SpecialOfferBanner.vue'

interface ITicket {
  id: number
  coins_per_hour: number
  stars_cost: number
  special_cost?: number
}

const mockAvailableTickets: ITicket[] = [
  { id: 1, stars_cost: 1000, coins_per_hour: 300 },
  { id: 2, stars_cost: 1000, coins_per_hour: 600 },
  { id: 3, stars_cost: 1000, coins_per_hour: 1200 },
  { id: 4, stars_cost: 1000, coins_per_hour: 2400, special_cost: 487 },
  { id: 5, stars_cost: 1000, coins_per_hour: 4800 },
  { id: 6, stars_cost: 1000, coins_per_hour: 9600 }
]

const ticketValue = ref(100)
const tonBalance = ref(5000)
const freeTicketProgress = ref(true)
const buyTicketsModal = ref(false)
const buyTicketModal = ref(false)
const selectedTicket = ref<ITicket | null>(null)

const availableTickets = ref<ITicket[]>([...mockAvailableTickets])

const { t } = useI18n()

const hasSpecialOffer = computed(() => availableTickets.value.some((el) => el.special_cost))

const buyTickets = () => {
  buyTicketsModal.value = true
}
const closeBuyTicketsModal = () => {
  buyTicketsModal.value = false
}
const selectTicket = (ticket: ITicket) => {
  selectedTicket.value = { ...ticket }

  buyTicketsModal.value = false
  buyTicketModal.value = true
}
const closeBuyTicketModal = () => {
  buyTicketModal.value = false
  selectedTicket.value = null
}
const buyTicket = () => {
  // ticket API
}
</script>

<template>
  <div class="tickets">
    <div style="max-width: 85%" class="ma-auto">
      <h2 class="mt-12 page-title yellow">
        {{ t('raffleTab.title') }}
      </h2>
    </div>

    <div style="max-width: 70%" class="ma-auto">
      <h3 class="page-sub-title mt-5">
        {{ t('raffleTab.description') }}
      </h3>
    </div>

    <InfoBanner class="mt-7" :title="t('raffleTab.raffleEndsIn')" yellow>
      <template #actions>
        <BtnPrimary :padding-x="30" yellow>
          <EventTimer red :size="16" :end-date="new Date('2024-10-27')" hide-seconds />
        </BtnPrimary>
      </template>
    </InfoBanner>

    <FreeTicket v-if="freeTicketProgress" class="mt-12" :price="500" />

    <RaffleAmount class="mt-4" :value="ticketValue" />

    <ExpansionPanel class="mt-8" more-btn-text="raffleTab.wantMoreTickets" :show-less-btn="false">
      <HowRaffleWorks class="mt-12" />
    </ExpansionPanel>

    <div class="ticketsBtn-container">
      <Container>
        <ActionBtn yellow :text="t('raffleTab.buyTickets')" @click="buyTickets">
          <template #append-icon>
            <IconTicketStar style="width: 24px; height: 24px" />
          </template>
        </ActionBtn>
      </Container>
    </div>

    <DialogModal
      v-if="buyTicketsModal"
      @close="closeBuyTicketsModal"
      :title="t('raffleTab.buyTickets')"
      yellow
    >
      <!-- specialOffer  -->
      <SpecialOfferBanner v-if="hasSpecialOffer" />

      <div class="earn-container">
        <ThreeRowsCard
          v-for="ticket in availableTickets"
          :key="`ticket-${ticket.id}`"
          :title="`${ticket.coins_per_hour}/${t('common.hr')}`"
          :cost="ticket.stars_cost"
          :discount="ticket.special_cost"
          @click="selectTicket(ticket)"
          yellow
        >
          <template #icon="{ _class }">
            <IconTicketStar
              class="text-primary"
              :class="_class"
              style="width: 32px; height: 32px"
            />
          </template>
        </ThreeRowsCard>
      </div>
    </DialogModal>

    <DialogModal
      v-if="buyTicketModal && selectedTicket"
      @close="closeBuyTicketModal"
      title="Buy ticket"
      yellow
    >
      <div class="mt-9">
        <div class="flex items-center justify-center">
          <div class="ticket-big">
            <div class="ticket-big-title">
              {{ `${selectedTicket.coins_per_hour}/${t('common.hr')}` }}
            </div>
            <IconDrop class="text-yellow" style="width: 43px; height: 43px" />
            <div class="flex gap-2 align0center">
              <IconStar style="width: 20px; height: 20px" class="text-yellow" />
              <div class="ticket-big-value">{{ amountFormat(selectedTicket.stars_cost) }}</div>
            </div>
          </div>
        </div>

        <ActionBtn class="mt-9" yellow :text="t('tab.claim')" @click="buyTicket"> </ActionBtn>
      </div>
    </DialogModal>
  </div>
</template>

<style scoped>
.tickets {
  padding-bottom: 110px;
}
.ticketsBtn-container {
  position: fixed;
  z-index: 99;
  bottom: 112px;
  left: 0;
  right: 0;
}
.ticket-big {
  width: 146px;
  height: 146px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--card-yellow-bg);
  border: 1px solid var(--card-yellow-border);
  border-radius: 16px;
  padding: 8px;
  gap: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  color: white;
}
.ticket-big-title {
  color: var(--white);
  font-family: Urbanist;
  font-size: 19.909px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.ticket-big-value {
  color: var(--yellow);
  font-family: Urbanist;
  font-size: 17.255px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.086px;
}
</style>
