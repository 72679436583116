<script setup lang="ts">
import IconBalance from '@/components/icons/IconBalance.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
  disabled?: boolean
  completed?: boolean
  title?: string
  subtitle?: string
  price?: string | number
  img?: string
  priceWithPlus?: boolean
  showIndex?: boolean
  index?: number
}

withDefaults(defineProps<Props>(), { disabled: false, completed: false, showIndex: false })
</script>

<template>
  <div class="item" :class="{ 'item-disabled': disabled, 'item-completed': completed }">
    <div v-if="showIndex && (index || index == 0)" class="rank-list-item-ind">
      {{ index }}
    </div>
    <div v-if="$slots.prepend" class="item-icon">
      <slot name="prepend" />
    </div>
    <slot v-if="$slots.default" />
    <template v-else>
      <div class="item-body">
        <slot name="title" v-if="$slots.title" />
        <h2 v-else class="item-body-title truncate">{{ title ? t(title) : title }}</h2>
        <slot name="subtitle" v-if="$slots.subtitle" />
        <div v-else class="item-body-subtitle">
          <slot name="subtitleIcon" v-if="$slots.subtitleIcon"></slot>
          <IconBalance v-else-if="price" style="width: 17px; height: 17px" />
          {{ `${priceWithPlus ? '+' : ''} ${price || price == 0 ? price : ''}` }}
          <span v-if="subtitle" class="sub-text">{{ subtitle }}</span>
        </div>
      </div>
    </template>
    <div v-if="$slots.preAppend">
      <slot name="preAppend" />
    </div>
    <div v-if="$slots.append" class="item-status text-primary">
      <slot name="append" />
    </div>
  </div>
</template>

<style scoped>
.item {
  display: flex;
  color: white;
  padding: 12px 16px;
  max-width: 100%;
  overflow: hidden;
  border-radius: 22px;
  border: 1px solid rgba(177, 247, 112, 0.46);
  background: radial-gradient(
    103.53% 111.49% at -1.76% 81.25%,
    rgba(150, 207, 96, 0.38) 0%,
    #314222 98.5%
  );
}
.item-disabled {
  opacity: 0.4;
}
.item-completed {
  /* opacity: 0.7; */
  background: var(--app-background-darkest);
  border: 1px solid var(--primary);
}
.item-icon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.item-completed .item-icon {
  opacity: 0.5;
}
.item-icon > * {
  width: 100%;
  height: 100%;
}
.item-body {
  padding-left: 12px;
  padding-right: 4px;
  flex-grow: 1;
  /* color: white; */
  letter-spacing: 0.05rem;
  align-self: center;
  overflow: hidden;
}
.item-completed .item-body {
  opacity: 0.6;
}
.item-body-title {
  color: var(--white, #ecf2e5);
  margin-right: 6px;
  overflow: hidden;
  text-align: start;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
}
.item-body-subtitle {
  font-weight: 700;
  line-height: 1.36;
  color: #e9e4e4;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--primary, #b2ff6b);
  margin-top: 4px;
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.065px;
}
.item-body-subtitle svg {
  width: 24px;
  height: 24px;
}
.item-status {
  display: flex;
  align-items: center;
  /* color: #b8b4b4; */
}
.rank-list-item-ind {
  font-weight: 700;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  color: var(--app-primary);
  padding: 2px 8px;
}
.sub-text {
  color: rgba(243, 255, 233, 0.6);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
  margin-left: 10px;
}
</style>
