<script setup lang="ts">
import { useUserTasksStore } from '@/stores/userTasks'
import { TAB_BOOSTS, TAB_EARNS, TAB_HOME, TAB_INVITE, TAB_RAFFLE } from '@/utils/tabs'
import { storeToRefs } from 'pinia'
import { shallowRef } from 'vue'

defineProps(['currentTab'])
defineEmits(['update:currentTab'])

const tabs = shallowRef([
  { title: TAB_HOME, img: 'home' },
  { title: TAB_BOOSTS, img: 'boost' },
  { title: TAB_INVITE, img: 'friends' },
  { title: TAB_RAFFLE, img: 'ruffle' },
  { title: TAB_EARNS, img: 'tasks' }
])

const { hasNewTask } = storeToRefs(useUserTasksStore())
</script>

<template>
  <div class="container tabs-container">
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.title"
        class="tab-img-container"
        :class="{
          newBadge: !hasNewTask && tab.title == TAB_EARNS,
          active: currentTab == tab.title
        }"
      >
        <img
          :src="`/img/tabs/${tab.img}.webp`"
          class="tab-img"
          @click="$emit('update:currentTab', tab.title)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.tabs-container {
  position: fixed;
  z-index: 10;
  bottom: 16px;
  left: 0;
  right: 0;
}
.tabs {
  gap: 8px;
  width: 100%;
  max-width: 440px;
  border-radius: 22px;
  border: 1px solid rgba(178, 255, 107, 0.59);
  background: #314222;
  padding: 10px 12px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0 auto;
}

.tab-img-container {
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  opacity: 0.7;
  transition: all 0.2s;
}
.tab-img-container.active {
  opacity: 1;
}
.tab-img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.newBadge::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--red);
  top: 8px;
  right: 8px;
  border-radius: 100%;
}
@media (min-width: 390px) {
  .tabs {
    gap: 14px;
    padding: 12px 16px;
  }
  /* .tab {
    padding: 8px 8px;
  } */
}
</style>
