<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="special-offer">
    {{ t('passiveIncome.specialOffer') }}
  </div>
</template>

<style scoped>
.special-offer {
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  /* gap: 44px; */
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid transparent;
  background: linear-gradient(276deg, #fe5258 19.52%, #b4ff72 207.4%);
  margin-top: 27px;
  margin-bottom: 27px;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 153.846% */
  text-transform: uppercase;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
}
.special-offer:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(to right, #fe5258 19.52%, #b4ff72 207.4%);
}
</style>
