<script setup lang="ts">
import { useUserTasksStore } from '@/stores/userTasks'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BtnPrimary from '../common/BtnPrimary.vue'
import CardWithBgGradient from '../common/CardWithBgGradient.vue'
import Container from '../common/Container.vue'
import DialogModal from '../common/DialogModal.vue'
import IconBack from '../icons/IconBack.vue'

defineEmits(['close'])

const { t } = useI18n()

const tasksStore = useUserTasksStore()
const { isTaskIsCompletedById } = storeToRefs(tasksStore)

const iconsArr = [
  { id: 1, url: '/img/puzzle/16.webp', color: 'rgba(180, 255, 114)' },
  { id: 2, url: '/img/puzzle/8.webp', color: 'rgba(253, 255, 107)' },
  { id: 3, url: '/img/puzzle/3.webp', color: 'rgba(254, 82, 88)' },
  { id: 4, url: '/img/puzzle/4.webp', color: 'rgba(180, 255, 114)' },
  { id: 5, url: '/img/puzzle/5.webp', color: 'rgba(243, 255, 233)' },
  { id: 6, url: '/img/puzzle/6.webp', color: 'rgba(47, 54, 40)' },
  { id: 7, url: '/img/puzzle/7.webp', color: 'rgba(113, 190, 43)' },
  { id: 8, url: '/img/puzzle/2.webp', color: 'rgba(253, 255, 107)' },
  { id: 9, url: '/img/puzzle/10.webp', color: 'rgba(180, 255, 114)' },
  { id: 10, url: '/img/puzzle/9.webp', color: 'rgba(254, 82, 88)' },
  { id: 11, url: '/img/puzzle/11.webp', color: 'rgba(243, 255, 233)' },
  { id: 12, url: '/img/puzzle/12.webp', color: 'rgba(47, 54, 40)' },
  { id: 13, url: '/img/puzzle/13.webp', color: 'rgba(113, 190, 43)' },
  { id: 14, url: '/img/puzzle/15.webp', color: 'rgba(254, 82, 88)' },
  { id: 15, url: '/img/puzzle/14.webp', color: 'rgba(253, 255, 107)' },
  { id: 16, url: '/img/puzzle/1.webp', color: 'rgba(113, 190, 43)' }
]
const selectedIcons = ref<any[]>([])
const showDialog = ref(false)
// const showTimerToNextGame = ref<Date | null>(null)
const icons = ref<any[]>([])

// const gameOver = computed(() => isTaskIsCompletedById.value(14))
const gameOver = ref(false)
const valid = computed(() => selectedIcons.value.length === 4)

const selectIcon = (icon: any) => {
  if (gameOver.value || selectedIcons.value.length > 3) return
  selectedIcons.value.push(icon)
}

const unSelectIcon = (index: number) => {
  if (gameOver.value) return
  selectedIcons.value.splice(index, 1)
}

const isSelected = (id: number) => {
  return selectedIcons.value.some((el) => el?.id == id)
}
const isWrongIcon = (ind: number) => {
  return selectedIcons.value[ind]?.incorrect
}
const checkCombination = async () => {
  if (!valid.value && !gameOver.value) {
    return
  }

  const ids = selectedIcons.value.map((el) => el.id)

  await tasksStore.completeTask(14, ids)
  // const res = await getUserDailyCombo()

  // gameOver.value = true
  // selectedIcons.value.forEach((icon) => {
  //   if (Object.keys(res).includes(icon.id)) {
  //     icon.incorrect = !res[icon.id]
  //   } else {
  //     icon.incorrect = true
  //   }
  // })
  // "1": true,
  // "3": true,
  // "4": false,
  // "6": false
}

const preloadImages = (iconsArr: any[]) => {
  return Promise.allSettled(
    iconsArr.map(({ url }) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url
        img.onload = resolve
        img.onerror = reject
      })
    })
  )
}

onMounted(async () => {
  await preloadImages(iconsArr)
  icons.value = iconsArr

  gameOver.value = isTaskIsCompletedById.value(14)
})
</script>

<template>
  <Container class="puzzle overflowOn">
    <div class="body">
      <div class="relative mt-7">
        <div class="page-title">
          {{ t('puzzleGame.puzzle') }}
        </div>
        <div class="page-sub-title text ma-auto">{{ t('puzzleGame.findCorrectCombination') }}</div>
        <IconBack
          class="back-icon text-light-green"
          style="width: 20px; height: 20px"
          @click="$emit('close')"
        />
      </div>

      <CardWithBgGradient class="answer-bar mt-7">
        <div
          v-for="n in 4"
          :key="n"
          class="answer-bar-item"
          :class="{ wrong: isWrongIcon(n - 1) }"
          @click="unSelectIcon(n - 1)"
        >
          <!-- :style="{ background: selectedIcons[n - 1] && selectedIcons[n - 1].color }" -->
          <img
            v-if="selectedIcons[n - 1] && !selectedIcons[n - 1].incorrect"
            :src="selectedIcons[n - 1].url"
            alt=""
          />
        </div>
      </CardWithBgGradient>

      <CardWithBgGradient class="variants-bar mt-4">
        <div
          v-for="icon in icons"
          :key="icon.id"
          class="variants-bar-item"
          :class="{ disabled: isSelected(icon.id) }"
          @click="selectIcon(icon)"
        >
          <!-- :style="{ background: icon.color }" -->
          <img :src="icon.url" alt="" />
        </div>
      </CardWithBgGradient>
    </div>

    <div class="action-bar mt-10">
      <BtnPrimary block big :disabled="!valid" @click="checkCombination">
        {{ t('puzzleGame.check') }}
      </BtnPrimary>
    </div>

    <DialogModal
      v-if="showDialog"
      @close="showDialog = false"
      :title="t('puzzleGame.notWithTime')"
      :description="t('puzzleGame.notLucky')"
    >
      <BtnPrimary block big class="mt-12" @click="showDialog = false"> Ok </BtnPrimary>
    </DialogModal>
  </Container>
</template>

<style scoped>
.puzzle {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.body {
  flex-grow: 1;
}
.answer-bar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.answer-bar-item,
.variants-bar-item {
  width: 100%;
  /* height: 81px; */
  aspect-ratio: 1;
  border-radius: 22px;
  border: 1px solid rgba(177, 247, 112, 0.46);
  background: #314321;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  padding: 2px;
}
.answer-bar-item img,
.variants-bar-item img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.answer-bar-item.wrong {
  position: relative;
}
.answer-bar-item.wrong::after,
.answer-bar-item.wrong::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background: var(--red);
  top: 50%;
  left: 50%;
  transform-origin: center;
}
.answer-bar-item.wrong::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.answer-bar-item.wrong::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.variants-bar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}
.variants-bar-item.disabled {
  opacity: 0.2;
}
.action-bar {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;*/
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 32px;
}
.text {
  max-width: 75%;
  align-self: center;
}
.next-game-text {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}
</style>
