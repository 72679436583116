import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useGlobalIntervalStore = defineStore('globalInterval', () => {
  const tick = ref(1)
  const intervalId = ref<any>(null)

  const setTick = () => {
    if (tick.value > 999) {
      tick.value = 1
      return
    }
    tick.value += 1
  }

  const startInterval = () => {
    intervalId.value = window.setInterval(() => {
      setTick()
    }, 1000)
  }
  const stopInterval = () => {
    if (intervalId.value) window.clearInterval(intervalId.value)
  }

  return {
    tick,
    startInterval,
    stopInterval
  }
})
