import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useApi } from './useApi'

export type Referral = App.DTO.Referral.ReferralData

export default function useReferral() {
  const { refererCode } = storeToRefs(useAuthStore())

  const referrals = ref<Referral[]>([])

  const copyableLink = computed<string | null>(() => {
    if (!refererCode.value) {
      return null
    }
    return `${import.meta.env.VITE_APP_DIRECT_LINK}/?startapp=refid${refererCode.value}`
  })

  const sharableLink = computed<string | null>(() => {
    if (!copyableLink.value) {
      return null
    }

    return `https://t.me/share/url?url=${copyableLink.value}&text=Join me in GDF and participate in a token airdrop! Welcome bonus to everyone:%0A💵 2,000 coins as a first gift! %0A💰 25,000 coins for Telegram Premium users!`
  })

  const getReferrals = async () => {
    referrals.value = (await useApi().get('/api/referrals')).data.data as Referral[]
  }

  return {
    referrals,
    getReferrals,
    sharableLink,
    copyableLink
  }
}
