<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  text?: string
  reverseBg?: boolean
  appendIconClasses?: string
  prependIconClasses?: string
  textClasses?: string
  active?: boolean
}
const props = withDefaults(defineProps<Props>(), { textClasses: '' })

const styles = computed(() => ({
  background: props.active
    ? `linear-gradient(276deg, #4B7526 19.52%, #B4FF72 207.4%)`
    : props.reverseBg
      ? `linear-gradient(103deg, #28361C 25.12%, #749C50 173.64%)`
      : `linear-gradient(276deg, #314222 19.52%, #7DA857 207.4%)`,
  border: props.active ? '1px solid #456129' : '1px solid var(--bg-semi-dark-green)'
}))
</script>

<template>
  <button :style="styles" class="btn">
    <div class="btn-text" :class="[$slots.appendIcon ? 'justify-between' : 'justify-center']">
      <div class="flex align-center gap-2">
        <!-- prependIcon  -->
        <div v-if="$slots.prependIcon" :class="prependIconClasses">
          <slot name="prependIcon" />
        </div>

        <!-- text  -->
        <div :class="[textClasses ? 'textClasses' : 'btn-text']">
          <template v-if="text">
            {{ text }}
          </template>
          <template v-else>
            <slot />
          </template>
        </div>
      </div>

      <!-- icon  -->
      <div v-if="$slots.appendIcon" :class="[appendIconClasses ? appendIconClasses : 'appendIcon']">
        <slot name="appendIcon" />
      </div>
    </div>
  </button>
</template>

<style scoped>
.btn-text {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--text, #f3ffe9);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 1; /* 153.846% */
  /* text-wrap: nowrap; */
}
.btn {
  border-radius: 12px;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  width: 100%;
}
.appendIcon {
  width: 14px;
  height: 14px;
}
</style>
