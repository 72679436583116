import {
  getPredictionTournament,
  getPredictionTournamentCurrencyRounds,
  getPredictionTournaments,
  getTournamentRating,
  getTournamentsScore,
  registerForPredictionTournament,
  type PredictionPayload
} from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

type TournamentRating = App.DTO.PredictionTournament.PredictionTournamentUsersRatingData

export type Tournament = App.DTO.PredictionTournament.PredictionTournamentData

export type TournamentCurrencyRound = Omit<
  App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData,
  'registration_starts_at' | 'started_at' | 'ended_at'
> & {
  registration_starts_at: Date
  started_at: Date
  ended_at: Date
}

export const useTournamentStore = defineStore('tournament', () => {
  const tournamentRating = ref<TournamentRating[]>([])
  const tournamentCategories = ref<Tournament[]>([])
  const tournament = ref<App.DTO.PredictionTournament.PredictionTournamentData | null>(null)
  const tournamentCurrencyRounds = ref<TournamentCurrencyRound[]>([])
  const totalScoreByTournaments = ref<
    App.DTO.PredictionTournament.PredictionTournamentsScoreData[]
  >([])

  const selectedCategory = ref<Tournament | null>(null)
  const selectedCurrencyRound = ref<TournamentCurrencyRound | null>(null)

  function registerForTournament(phaseId: number) {
    console.log('phaseId: ', phaseId)
  }

  async function getTournamentById(tournamentId: number) {
    try {
      tournament.value = await getPredictionTournament(tournamentId)
    } catch (e) {
      // tournament.value = {
      //   id: 4,
      //   title: 'tournamentTab.tournaments.categories.oneDay',
      //   description: 'tournamentTab.tournaments.categories.oneDayDescription',
      //   attemptsMax: 2,
      //   attemptsMade: 0,
      //   totalScore: 0
      // }
    }
  }

  const attemptsLeft = computed(
    () => (tournament.value?.attemptsMax || 0) - (tournament.value?.attemptsMade || 0)
  )

  async function getTournamentCategories() {
    try {
      tournamentCategories.value = await getPredictionTournaments()
    } catch (e) {
      // tournamentCategories.value = [
      //   {
      //     id: 1,
      //     title: 'tournamentTab.tournaments.categories.fiveMin',
      //     description: 'tournamentTab.tournaments.categories.fiveMinDescription',
      //     attemptsMax: 10,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   },
      //   {
      //     id: 2,
      //     title: 'tournamentTab.tournaments.categories.fifteenMin',
      //     description: 'tournamentTab.tournaments.categories.fifteenMinDescription',
      //     attemptsMax: 7,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   },
      //   {
      //     id: 3,
      //     title: 'tournamentTab.tournaments.categories.oneHour',
      //     description: 'tournamentTab.tournaments.categories.oneHourDescription',
      //     attemptsMax: 3,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   },
      //   {
      //     id: 4,
      //     title: 'tournamentTab.tournaments.categories.oneDay',
      //     description: 'tournamentTab.tournaments.categories.oneDayDescription',
      //     attemptsMax: 2,
      //     attemptsMade: 0,
      //     totalScore: 0
      //   }
      // ]
    }
  }

  async function getTournamentCategoryById(tournamentId: number) {
    try {
      const result = await getPredictionTournamentCurrencyRounds(tournamentId)

      tournamentCurrencyRounds.value = (result || []).map((round) =>
        convertPredictionTournamentCurrencyRoundDataToTournamentCurrencyRound(round)
      )
    } catch (e) {
      // tournamentCurrencyRounds.value = [
      //   {
      //     id: 1605,
      //     title: 'BTCUSDT',
      //     registration_starts_at: new Date('2024-11-14T09:06:00+00:00'),
      //     started_at: new Date('2024-11-14T10:01:00+00:00'),
      //     ended_at: new Date('2024-11-15T09:00:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'btc'
      //   },
      //   {
      //     id: 1606,
      //     title: 'ETHUSDT',
      //     registration_starts_at: new Date('2024-11-14T09:06:00+00:00'),
      //     started_at: new Date('2024-11-14T10:01:00+00:00'),
      //     ended_at: new Date('2024-11-15T09:00:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'eth'
      //   },
      //   {
      //     id: 1607,
      //     title: 'SOLUSDT',
      //     registration_starts_at: new Date('2024-11-14T09:06:00+00:00'),
      //     started_at: new Date('2024-11-14T10:01:00+00:00'),
      //     ended_at: new Date('2024-11-15T09:00:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'sol'
      //   },
      //   {
      //     id: 1608,
      //     title: 'TONUSDT',
      //     registration_starts_at: new Date('2024-11-14T09:06:00+00:00'),
      //     started_at: new Date('2024-11-14T10:01:00+00:00'),
      //     ended_at: new Date('2024-11-15T09:00:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'ton'
      //   },
      //   {
      //     id: 1553,
      //     title: 'BTCUSDT',
      //     registration_starts_at: new Date('2024-11-13T16:31:00+00:00'),
      //     started_at: new Date('2024-11-13T17:26:00+00:00'),
      //     ended_at: new Date('2024-11-14T16:25:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'btc'
      //   },
      //   {
      //     id: 1554,
      //     title: 'ETHUSDT',
      //     registration_starts_at: new Date('2024-11-13T16:31:00+00:00'),
      //     started_at: new Date('2024-11-13T17:26:00+00:00'),
      //     ended_at: new Date('2024-11-14T16:25:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'eth'
      //   },
      //   {
      //     id: 1555,
      //     title: 'SOLUSDT',
      //     registration_starts_at: new Date('2024-11-13T16:31:00+00:00'),
      //     started_at: new Date('2024-11-13T17:26:00+00:00'),
      //     ended_at: new Date('2024-11-14T16:25:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'sol'
      //   },
      //   {
      //     id: 1556,
      //     title: 'TONUSDT',
      //     registration_starts_at: new Date('2024-11-13T16:31:00+00:00'),
      //     started_at: new Date('2024-11-13T17:26:00+00:00'),
      //     ended_at: new Date('2024-11-14T16:25:59+00:00'),
      //     is_registered: false,
      //     user_bid: null,
      //     ticker: 'ton'
      //   }
      // {
      //     "id": 1381,
      //     "title": "BTCUSDT",
      //     "registration_starts_at": new Date("2024-11-13T12:21:00+00:00"),
      //     "started_at": new Date("2024-11-13T13:16:00+00:00"),
      //     "ended_at": new Date("2024-11-14T12:15:59+00:00"),
      //     "is_registered": false,
      //     "user_bid": null,
      //     "ticker": "btc"
      // },
      // {
      //     "id": 1382,
      //     "title": "ETHUSDT",
      //     "registration_starts_at": new Date("2024-11-13T12:21:00+00:00"),
      //     "started_at": new Date("2024-11-13T13:16:00+00:00"),
      //     "ended_at": new Date("2024-11-14T12:15:59+00:00"),
      //     "is_registered": false,
      //     "user_bid": null,
      //     "ticker": "eth"
      // },
      // {
      //     "id": 1383,
      //     "title": "SOLUSDT",
      //     "registration_starts_at": new Date("2024-11-13T12:21:00+00:00"),
      //     "started_at": new Date("2024-11-13T13:16:00+00:00"),
      //     "ended_at": new Date("2024-11-14T12:15:59+00:00"),
      //     "is_registered": false,
      //     "user_bid": null,
      //     "ticker": "sol"
      // },
      // {
      //     "id": 1384,
      //     "title": "TONUSDT",
      //     "registration_starts_at": new Date("2024-11-13T12:21:00+00:00"),
      //     "started_at": new Date("2024-11-13T13:16:00+00:00"),
      //     "ended_at": new Date("2024-11-14T12:15:59+00:00"),
      //     "is_registered": false,
      //     "user_bid": null,
      //     "ticker": "ton"
      // }
      // ]
      // console.log('e: ')
      // throw e
    }
  }

  function convertPredictionTournamentCurrencyRoundDataToTournamentCurrencyRound(
    data: App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData
  ): TournamentCurrencyRound {
    return {
      ...data,
      registration_starts_at: new Date(data.registration_starts_at),
      started_at: new Date(data.started_at),
      ended_at: new Date(data.ended_at)
    }
  }

  async function registerUserPredictionForRound(roundId: number, prediction: PredictionPayload) {
    await registerForPredictionTournament(roundId, prediction)
  }

  async function getRatings() {
    try {
      tournamentRating.value = await getTournamentRating()
    } catch (e) {
      // tournamentRating.value = [
      //   {
      //     userId: 1,
      //     profileImageURL: '',
      //     rankName: 'rank',
      //     totalScore: '4000',
      //     tournamentsCount: 200,
      //     userName: 'user-1',
      //     winsCount: 55
      //   }
      // ]
    }
  }

  function setRegistrationStatusForRound(
    roundId: number,
    registered: boolean,
    variant: 'up' | 'down'
  ) {
    const roundIndex = tournamentCurrencyRounds.value.findIndex((round) => round.id === roundId)

    if (roundIndex !== -1) {
      tournamentCurrencyRounds.value[roundIndex].is_registered = registered
      tournamentCurrencyRounds.value[roundIndex].user_bid = variant
      if (tournament.value) tournament.value.attemptsMade += 1
    }
    if (selectedCurrencyRound.value) {
      selectedCurrencyRound.value.is_registered = registered
      selectedCurrencyRound.value.user_bid = variant
      // if (tournament.value) tournament.value.attemptsMade += 1
    }
  }

  async function getHistory() {
    totalScoreByTournaments.value = await getTournamentsScore()
  }

  async function pushCurrencyRound(
    round: App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData
  ) {
    tournamentCurrencyRounds.value.unshift(
      convertPredictionTournamentCurrencyRoundDataToTournamentCurrencyRound(round)
    )
  }

  return {
    tournamentRating,
    getRatings,
    tournament,
    registerForTournament,
    getTournamentCategoryById,
    tournamentCategories,
    getTournamentCategories,
    tournamentCurrencyRounds,
    getTournamentById,
    registerUserPredictionForRound,
    setRegistrationStatusForRound,
    getHistory,
    totalScoreByTournaments,
    attemptsLeft,
    selectedCategory,
    selectedCurrencyRound,
    pushCurrencyRound
  }
})
