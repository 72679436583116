<script setup lang="ts">
import { getUserDailyCombo } from '@/application/services/useApi'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CardWithBgGradient from './common/CardWithBgGradient.vue'
import Container from './common/Container.vue'
import DialogModal from './common/DialogModal.vue'
import EventTimer from './common/EventTimer.vue'
import FullWindowModal from './common/FullWindowModal.vue'
import DailyCombo from './games/DailyCombo.vue'
import LuckyTrade from './games/LuckyTrade.vue'
import QuizGame from './games/QuizGame.vue'
import IconBack from './icons/IconBack.vue'
import IconCheck from './icons/IconCheck.vue'

type Games = 'quiz' | 'combo' | 'prediction'

defineEmits(['close'])

const { t } = useI18n()

const selectedGame = ref<Games | null>(null)
const comboPlayedToday = ref(true)
const showTimerToNextCombo = ref(false)

const selectGameHandler = (game: Games) => {
  if (game == 'combo') {
    if (comboPlayedToday.value) {
      showTimerToNextCombo.value = true
      return
    }
    selectedGame.value = 'combo'
  }
}

const nextDayTimer = () => {
  const day = new Date()
  const nextDay = day.setDate(day.getDate() + 1)
  const startOfTheDay = new Date(nextDay).setHours(0, 0, 0, 0)
  return new Date(startOfTheDay)
}

// const remindHandler = () => {
//   showTimerToNextCombo.value = false
//   // remind ?
// }

onMounted(async () => {
  try {
    const res = await getUserDailyCombo()
    if (res) {
      comboPlayedToday.value = true
    }
  } catch (e) {
    //
  }
})
</script>

<template>
  <Container>
    <div class="relative mt-7">
      <div class="ma-auto games-title">
        <div class="page-title">
          {{ t('games.title') }}
        </div>
        <div class="page-sub-title mt-5">
          {{ t('games.description') }}
        </div>
        <IconBack
          class="back-icon text-light-green"
          style="width: 20px; height: 20px"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div class="games-box">
      <CardWithBgGradient class="quiz" @click="selectedGame = 'quiz'">
        <div class="games-box-title">{{ t('games.quiz') }}</div>
        <img src="@/assets/img/quizImg.png " alt="" />
      </CardWithBgGradient>
      <CardWithBgGradient class="combo" @click="selectGameHandler('combo')">
        <div class="games-box-title">{{ t('games.combo') }}</div>
        <img src="@/assets/img/comboImg.png " alt="" />
        <IconCheck
          v-if="comboPlayedToday"
          class="combo-completed text-primary"
          style="width: 40px; height: 40px"
        />
      </CardWithBgGradient>
      <CardWithBgGradient class="prediction" @click="selectedGame = 'prediction'">
        <div class="games-box-title">{{ t('games.prediction') }}</div>
        <img src="@/assets/img/predictionImg.png" alt="" />
        <img src="@/assets/img/predictionImg.png" alt="" />
        <img src="@/assets/img/predictionImg.png" alt="" />
      </CardWithBgGradient>
    </div>

    <FullWindowModal v-if="selectedGame">
      <DailyCombo v-if="selectedGame == 'combo'" @close="selectedGame = null" />
      <LuckyTrade v-if="selectedGame == 'prediction'" @close="selectedGame = null" />
      <QuizGame v-if="selectedGame == 'quiz'" @close="selectedGame = null" />
    </FullWindowModal>

    <DialogModal v-if="showTimerToNextCombo" @close="showTimerToNextCombo = false">
      <div class="next-game-text">{{ t('puzzleGame.nextGameStarts') }}</div>
      <EventTimer
        class="mb-10"
        :size="48"
        :end-date="nextDayTimer()"
        hide-letters
        divider-symbol=" : "
      />

      <!-- <BtnPrimary block big class="mt-12" @click="remindHandler"> Remind me </BtnPrimary> -->
    </DialogModal>
  </Container>
</template>

<style scoped>
.games-title {
  max-width: 80%;
}
.games-box {
  margin-top: 54px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.games-box-title {
  font-family: Urbanist;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: center;
}

.games-box .quiz,
.games-box .combo,
.games-box .prediction {
  position: relative;
  height: 150px;
}
.games-box .prediction .games-box-title {
  max-width: 117px;
  text-align: start;
}

.games-box .quiz {
  grid-area: 1 / 1 / 2 / 2;
}

.games-box .quiz img,
.games-box .combo img,
.games-box .prediction img,
.games-box .combo-completed {
  position: absolute;
}

.games-box .quiz img {
  width: 109px;
  height: 92px;
  bottom: 0;
  right: 0;
}
.games-box .combo img {
  width: 109px;
  height: 72px;
  bottom: 10px;
  right: 14px;
}

.games-box .combo-completed {
  position: absolute;
  top: 10px;
  right: 20px;
}

.games-box .prediction img:nth-of-type(1) {
  width: 107px;
  height: 97px;
  bottom: -54px;
  left: 22%;
}
.games-box .prediction img:nth-of-type(2) {
  width: 75px;
  height: 68px;
  top: 14px;
  left: 46%;
}
.games-box .prediction img:nth-of-type(3) {
  width: 182px;
  height: 167px;
  bottom: -85px;
  right: -10%;
}

.games-box .combo {
  grid-area: 1 / 2 / 2 / 3;
}
.games-box .prediction {
  grid-area: 2 / 1 / 3 / 3;
}
</style>
