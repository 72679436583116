<script setup lang="ts">
import { userTaskSequence } from '@/mockData/daily'
import { type Task } from '@/stores/userTasks'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { Carousel, Slide } from 'vue3-carousel'
import IconBalance from './icons/IconBalance.vue'

type SequentialTask = {
  id: number
  reward: number | string
  is_completed: boolean
  image?: string
  sub_task_cycle_id: number
}

interface Props {
  currentTask: Task | null
}

const props = defineProps<Props>()
const emit = defineEmits(['completeTask', 'showReward'])

const { t } = useI18n()

const currentCycle = ref(1)
const myCarousel = ref<any>(null)

const taskDays = computed<SequentialTask[]>(() => {
  if (
    !props.currentTask?.user_task_sequence ||
    props.currentTask?.user_task_sequence.length === 0
  ) {
    return userTaskSequence as SequentialTask[]
  }

  return props.currentTask.user_task_sequence
  // .filter(
  //   (subTask) => subTask.sub_task_cycle_id === currentCycle.value
  // )
})

const cycles = computed(() => {
  const cycleId: number[] = []

  return taskDays.value.reduce((acc, cur) => {
    if (cycleId.includes(cur.sub_task_cycle_id)) return acc

    cycleId.push(cur.sub_task_cycle_id)
    return ++acc
  }, 0)
})

const currentCycleTasks = computed(() =>
  taskDays.value.filter((el) => el.sub_task_cycle_id == currentCycle.value).slice(0, 7)
)

onMounted(() => {
  currentCycle.value = taskDays.value.find((el) => !el.is_completed)?.sub_task_cycle_id || 1
})

const completeTaskHandler = (day: SequentialTask) => {
  if (day.is_completed) return
  if (day.image) emit('showReward', day.image)
  emit('completeTask')
}
watch(currentCycle, () => {
  if (myCarousel.value) myCarousel.value.slideTo(currentCycle.value)
})
</script>

<template>
  <div class="task">
    <div style="max-width: 75%" class="ma-auto">
      <h2 class="page-title">{{ t('earnTab.daily.title') }}!</h2>
      <h3 class="page-sub-title mt-5">{{ t('earnTab.daily.description') }}</h3>
    </div>

    <div class="task-cycle mt-8">
      <Carousel
        ref="myCarousel"
        :items-to-show="3.5"
        :breakpoints="{
          200: {
            itemsToShow: 3
          },
          400: {
            itemsToShow: 3.5
          }
        }"
      >
        <Slide v-for="item in cycles" :key="item">
          <div
            class="task-cycle-item"
            :class="{ active: currentCycle == item }"
            @click="currentCycle = item"
          >
            {{ `${item} ${t('earnTab.daily.cycle')}` }}
          </div>
        </Slide>
      </Carousel>
    </div>

    <div class="task-track">
      <div
        class="task-card"
        v-for="(day, ind) in currentCycleTasks"
        :key="day.id"
        :class="[
          {
            'task-card-big': ind == 6,
            done: day.is_completed
          },
          `task-card-${ind + 1}`
        ]"
        @click="completeTaskHandler(day)"
      >
        <template v-if="ind < 6">
          <div class="task-card-icon mt-1">
            <img v-if="day.image" :src="day.image" :alt="'' + day.reward" />
            <IconBalance v-else style="width: 38px; height: 38px" />
          </div>
          <div class="task-card-value text-primary">
            {{ day.reward }}
          </div>
          <div class="task-card-title mt-2">Day {{ day.id }}</div>
        </template>

        <div v-else-if="ind == 6" class="big-card">
          <div class="big-card-body">
            <div class="big-card-title">Day {{ day.id }}</div>
            <div class="big-card-reward text-primary">
              {{ t(day.reward) }}
            </div>
          </div>
          <div class="big-card-img">
            <img v-if="day.image" :src="day.image" :alt="'' + day.reward" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.task {
  padding-bottom: 36px;
}
.task-cycle {
  width: 100%;
}

.task-cycle-item {
  width: 100%;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #415b27;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  text-wrap: nowrap;
  margin-right: 8px;
}
.task-cycle-item.active {
  background: linear-gradient(103deg, #28361c 25.12%, #749c50 173.64%);
}

.task-track {
  margin-top: 26px;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.task-card-1 {
  grid-area: 1 / 1 / 2 / 2;
}
.task-card-2 {
  grid-area: 1 / 2 / 2 / 3;
}
.task-card-3 {
  grid-area: 1 / 3 / 2 / 4;
}
.task-card-4 {
  grid-area: 1 / 4 / 2 / 5;
}
.task-card-5 {
  grid-area: 2 / 1 / 3 / 2;
}
.task-card-6 {
  grid-area: 2 / 2 / 3 / 3;
}
.task-card-7 {
  grid-area: 2 / 3 / 3 / 5;
  position: relative;
  overflow: hidden;
}

.task-card {
  border-radius: 12px;
  border: 1px solid #5c7b3d;
  background: radial-gradient(
    103.53% 111.49% at -1.76% 81.25%,
    rgba(150, 207, 96, 0.38) 0%,
    #314222 98.5%
  );

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  flex-grow: 0;
  flex-shrink: 0;
}
.task-card-big {
  border: 1px solid #d1ffa6;
  background: radial-gradient(
    103.53% 189.49% at 22% 81.25%,
    rgba(150, 207, 96, 0.4) 0%,
    #314222 98.5%
  );
}
.task-card.done {
  background: radial-gradient(103.53% 111.49% at -1.76% 81.25%, #71be2b 0%, #497620 98.5%);
}
.task-card-icon {
  width: 41px;
  height: 38px;
}
.task-card-icon img,
.big-card-img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.task-card-value {
  color: var(--primary, #b2ff6b);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06px;
}
.task-card-title {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06px;
}
.big-card {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.big-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.big-card-img {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50.667px;
  flex-shrink: 0;
  position: relative;
  /* overflow: hidden; */
}
.big-card-img img {
  max-height: 100%;
  aspect-ratio: 1;
  border-radius: 100%;
}
.big-card-title {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.075px;
}
.big-card-reward {
  margin-top: 6px;
  color: var(--primary);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.065px;
}
.task-card-7::after,
.task-card-7::before {
  position: absolute;
  content: '';
  border-radius: 100%;
  flex-shrink: 0;
}
.task-card-7::after {
  bottom: -33px;
  left: 40px;
  background: linear-gradient(0deg, rgba(178, 255, 107, 0.32) 27.47%, rgba(107, 153, 64, 0.4) 100%);
  border: 3.243px solid #5f823f;
  /* border: 3.243px solid rgba(178, 255, 107, 0.7); */
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 0.69px 0.69px rgba(0, 0, 0, 0.18));
}
.task-card-7::before {
  top: -7px;
  left: 63px;
  width: 32px;
  height: 32px;
  background: linear-gradient(0deg, rgba(178, 255, 107, 0.32) 27.47%, rgba(107, 153, 64, 0) 100%);
  border: 2.076px solid #5f823f;
  /* border: 2.076px solid rgba(178, 255, 107, 0.7); */
  filter: drop-shadow(0px 0.441px 0.441px rgba(0, 0, 0, 0.18));
}
.big-card-img::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
  background: linear-gradient(0deg, rgba(178, 255, 107, 0.32) 27.47%, rgba(107, 153, 64, 0) 100%);
  border: 4.67px solid rgba(95, 130, 63, 1);
  border-radius: 100%;
  filter: drop-shadow(0px 0.993px 0.993px rgba(0, 0, 0, 0.18));
}
</style>
