<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import IconMore from '../icons/IconMore.vue'
import BtnText from './BtnText.vue'

withDefaults(
  defineProps<{
    moreBtnText?: string
    lessBtnText?: string
    showLessBtn?: boolean
  }>(),
  {
    moreBtnText: 'common.howItWorks',
    lessBtnText: 'common.hide',
    showLessBtn: true
  }
)
const emits = defineEmits(['more', 'less'])

const { t } = useI18n()

const show = ref(false)

const showMore = () => {
  show.value = !show.value

  emits('more')
}
const showLess = () => {
  show.value = false
  emits('less')
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-center">
      <BtnText @click="showMore">
        {{ t(moreBtnText) }}
        <template #appendIcon>
          <IconMore style="width: 14px; height: 14px" :class="{ rotate90: show }" />
        </template>
      </BtnText>
    </div>

    <slot v-if="show" />

    <div v-if="show && showLessBtn" class="mt-4 flex justify-center">
      <BtnText @click="showLess">
        {{ t(lessBtnText) }}
        <template #appendIcon>
          <IconMore style="width: 14px; height: 14px; transform: rotate(270deg)" />
        </template>
      </BtnText>
    </div>
  </div>
</template>

<style scoped></style>
