<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import FullWindowModal from './FullWindowModal.vue'

const props = withDefaults(defineProps<{ win?: boolean; startBid: number; endBid: number }>(), {
  win: true,
  startBid: 0,
  endBid: 0
})

const { t } = useI18n()

const difference = computed(() => {
  const diff = props.endBid - props.startBid
  const percentageDiff = (diff / (props.startBid || 1)).toFixed(8)
  return `${diff >= 0 ? '+' : ''}${percentageDiff}`
})
</script>

<template>
  <FullWindowModal :opacity="0.9" class="body">
    <div class="result" :class="[win ? 'gradient-text' : 'gradient-text_red']">
      {{ win ? t('luckyTrade.results.win') : t('luckyTrade.results.lose') }}
    </div>
    <div class="text" v-html="t('luckyTrade.results.btcPrice', { diff: difference })"></div>
    <div class="subtext">{{ t('luckyTrade.results.from', { from: startBid, to: endBid }) }}</div>
  </FullWindowModal>
</template>

<style scoped>
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.result {
  font-family: Urbanist;
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}
.text {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.subtext {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
}
</style>
