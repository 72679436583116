<script setup lang="ts">
import { subscribeToTicker, unSubscribeToTicker } from '@/application/services/tickersPrice'
import { useUserBalanceStore } from '@/stores/userBalance'
import { amountFormat, fixedDigitsAfterPoint } from '@/utils/amountFormat'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, onMounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import BalanceWidget from '../common/BalanceWidget.vue'
import CardWithBgGradient from '../common/CardWithBgGradient.vue'
import Container from '../common/Container.vue'
import DialogModal from '../common/DialogModal.vue'
import EventTimer from '../common/EventTimer.vue'
import ResultsWidget from '../common/ResultsWidget.vue'
import IconBack from '../icons/IconBack.vue'
import IconDecrease from '../icons/IconDecrease.vue'
import IconIncrease from '../icons/IconIncrease.vue'
import PassiveIncomeSection from '../PassiveIncomeSection.vue'
import { useLuckyTradeStore } from '@/stores/luckyTradeStore'

interface Bet {
  id: number
  value: number
}
type BetVariant = 'up' | 'down'
const ROUND_SECONDS: number = 5

const { t } = useI18n()
const userBalanceStore = useUserBalanceStore()
const { balance } = storeToRefs(userBalanceStore)

const luckyTradeStore = useLuckyTradeStore()
const { bid, closeBet } = luckyTradeStore

const bets = shallowRef<Bet[]>([
  { id: 1, value: 5000 },
  { id: 2, value: 10000 },
  { id: 3, value: 25000 },
  { id: 4, value: 50000 }
])

type AggTrade = {
  aggTradeId: number
  price: string
}
type UserBet = App.DTO.SingleHandTournament.SingleHandTournamentUserBidData
type CloseBetData = App.DTO.SingleHandTournament.SingleHandTournamentCloseBetData

const aggTradeInfo = ref<AggTrade | null>(null)
const btcPrice = ref(0)
const endDate = ref<Date | undefined>(undefined)
const selectedBet = ref(bets.value[0])

const startBid = ref(0)
const endBid = ref(0)

const waitingResultsBet = ref<BetVariant | null>(null)
const showResults = ref(false)
const openPassiveIncomeModal = ref(false)
const unmounted = ref(false)
const win = ref(false)

const updateTicker = (aggTrade: AggTrade) => {
  btcPrice.value = +aggTrade.price
  aggTradeInfo.value = aggTrade
}

const notEnoughCoins = computed(() => balance.value < bets.value[0].value)

onMounted(() => {
  subscribeToTicker('btcusdt', updateTicker)
})
onBeforeUnmount(() => {
  unmounted.value = true
  unSubscribeToTicker('btcusdt')
})

const selectBet = (bet: Bet) => {
  if (bet.value > balance.value || waitingResultsBet.value) return
  selectedBet.value = bet
}

const waitTimeout = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(unmounted.value ? false : true)
    }, ROUND_SECONDS * 1000)
  })
}

const setNewBetValue = () => {
  const nearestBet = [...bets.value].reverse().find((el) => el.value < balance.value)
  if (nearestBet) {
    selectedBet.value = { ...nearestBet }
  }
}

const makeBet = async (variant: BetVariant) => {
  if (waitingResultsBet.value || (selectedBet.value && balance.value < selectedBet.value.value))
    return
  const dateNow = new Date(Date.now())

  const secondsNow = dateNow.getSeconds()
  const datePlusRoundSeconds = dateNow.setSeconds(secondsNow + ROUND_SECONDS + 1)

  endDate.value = new Date(datePlusRoundSeconds)

  waitingResultsBet.value = variant
  startBid.value = btcPrice.value

  const res = await waitTimeout()

  if (!aggTradeInfo.value || !res) {
    resetValues()
    return
  }

  const userBet: UserBet = {
    aggTradeId: aggTradeInfo.value.aggTradeId,
    aggTradePrice: aggTradeInfo.value.price,
    userBidAmount: selectedBet.value.value,
    priceMovementDirectionBid: variant
  }

  await bid(userBet)

  if (res) {
    const closeBetData: CloseBetData = {
      aggTradeId: aggTradeInfo.value.aggTradeId,
      aggTradePrice: aggTradeInfo.value.price
    }

    const betResult = await closeBet(closeBetData)

    win.value = betResult.is_winner
    endBid.value = Number(betResult.end_agg_trade_price)

    waitingResultsBet.value = null
    showResults.value = true

    if (balance.value < selectedBet.value.value) setNewBetValue()
  } else {
    resetValues()
  }
}
const resetValues = () => {
  startBid.value = 0
  endBid.value = 0
}
const closeResultsWidget = () => {
  resetValues()
  showResults.value = false
}
</script>

<template>
  <Container class="game">
    <div class="relative mt-10">
      <div v-if="!waitingResultsBet" class="mt-5">
        <div class="available-coins gradient-text">{{ t('luckyTrade.availablePoints') }}</div>
        <BalanceWidget class="mt-3" :balance="balance" />
        <div class="need-more mt-1" @click="openPassiveIncomeModal = true">
          {{ t('luckyTrade.needMoreCoins') }}
        </div>
      </div>
      <div v-else class="timer-container mt-11 mb-8">
        <EventTimer
          :end-date="endDate"
          :size="60"
          hide-days
          divider-symbol=":"
          hide-letters
          hide-hours
        />
      </div>

      <IconBack
        class="back-icon text-light-green"
        style="width: 20px; height: 20px; top: 20%"
        @click="$emit('close')"
      />
    </div>

    <div class="mt-7 flex justify-center">
      <div class="price-circle" :class="{ spin: waitingResultsBet }">
        <img src="/img/rotationCoin.png" alt="" />
        <div class="price-inner flex flex-col items-center justify-center">
          <div class="ticker-name">{{ t('luckyTrade.btcPrice') }}</div>
          <div class="ticker-value mt-2 mb-2">${{ fixedDigitsAfterPoint(btcPrice) }}</div>
          <!-- <div class="ticker-text" v-html="t('luckyTrade.priceFrom')"></div> -->
          <div class="ticker-text">
            {{ startBid ? `$${fixedDigitsAfterPoint(startBid)}` : ' ' }}
          </div>
        </div>
      </div>
    </div>

    <div class="bet-variants mt-7">
      <CardWithBgGradient
        v-for="bet in bets"
        :key="bet.id"
        class="bet-variants-item"
        :class="{ selected: selectedBet.id == bet.id, disabled: balance < bet.value }"
        @click="selectBet(bet)"
        style="border-radius: 16px"
      >
        <div class="flex items-center justify-center w-100 bet-variants-value">
          {{ amountFormat(bet.value) }}
        </div>
      </CardWithBgGradient>
    </div>

    <div class="mt-auto mb-8">
      <div class="mt-10 info-text">
        {{ t('luckyTrade.guessPrice') }}
      </div>

      <div
        class="mt-5 flex justify-center gap-4 items-center btn"
        :class="{ blurred: waitingResultsBet || notEnoughCoins }"
      >
        <button
          class="betBtn decrease"
          @click="makeBet('down')"
          :class="{ highlighted: waitingResultsBet == 'down' }"
        >
          {{ t('luckyTrade.down') }}
          <IconDecrease style="width: 24px; height: 24px" />
        </button>
        <button
          class="betBtn increase"
          @click="makeBet('up')"
          :class="{ highlighted: waitingResultsBet == 'up' }"
        >
          {{ t('luckyTrade.up') }}
          <IconIncrease style="width: 24px; height: 24px" />
        </button>
      </div>
    </div>

    <DialogModal
      v-if="openPassiveIncomeModal"
      @close="openPassiveIncomeModal = false"
      :title="t('passiveIncome.passiveEarn')"
      :description="t('passiveIncome.passiveEarnDescription')"
    >
      <div class="mt-3 mb-10">
        <PassiveIncomeSection @close="openPassiveIncomeModal = false" />
      </div>
    </DialogModal>

    <ResultsWidget
      v-if="showResults"
      @click="closeResultsWidget"
      :win="win"
      :start-bid="startBid"
      :end-bid="endBid"
    />
  </Container>
</template>

<style scoped>
.game {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.available-coins {
  text-align: center;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.09px;
}
.need-more {
  color: var(--white, #f3ffe9);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.timer-container {
}
.price-circle {
  width: 260px;
  height: 260px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}
.price-circle.spin img {
  animation: spin 2s linear infinite;
}
.price-circle svg {
  width: 100%;
  height: 100%;
}
.price-inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.ticker-name {
  text-transform: capitalize;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ticker-value {
  color: var(--white, #f3ffe9);
  font-family: Courier, monospace;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.ticker-text {
  color: #fff;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 40%;
  text-align: center;
  height: 14.5px;
}
.bet-variants {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
.bet-variants-item.disabled {
  opacity: 0.4;
}
.bet-variants-item.selected {
  background: radial-gradient(
    103.53% 111.49% at -1.76% 81.25%,
    rgba(156, 202, 115, 0.8) 0%,
    rgba(207, 255, 162, 0.38) 98.5%
  );
  border: 1px solid rgba(177, 247, 112, 0.7);
  transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.bet-variants-value {
  color: var(--main, #b2ff6b);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.info-text {
  color: var(--white, #f3ffe9);
  text-align: center;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.betBtn {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.betBtn.increase,
.betBtn.decrease {
  color: var(--black, #0d0e0c);
  font-family: Urbanist;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  width: 100%;
  max-width: 200px;
  padding: 18px 16px;
  text-transform: capitalize;
}
.betBtn.increase {
  border-radius: 12px;
  background: linear-gradient(123deg, #d1ffa6 6.07%, #71be2b 82.15%);

  /* button */
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}
.betBtn.decrease {
  border-radius: 12px;
  background: linear-gradient(123deg, #ffc1c3 6.07%, #fe5258 82.15%);
  /* button */
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}
.btn.blurred {
  opacity: 0.3;
}
.betBtn.highlighted {
  box-shadow: 0 0px 18px 10px var(--primary);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
