<script lang="ts" setup>
import { amountFormat } from '@/utils/amountFormat'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './common/Button.vue'
import CardWithBorderGradient from './common/CardWithBorderGradient.vue'
import IconBalance from './icons/IconBalance.vue'

const { t } = useI18n()

const tapBoosts = computed(() => [
  { id: 1, level: t('boostTab.howItWorks.lvl', { level: 1 }), coins: 1, price: 0 },
  { id: 2, level: t('boostTab.howItWorks.lvl', { level: 2 }), coins: 3, price: 4000 },
  { id: 3, level: t('boostTab.howItWorks.lvl', { level: 3 }), coins: 5, price: 8000 },
  { id: 4, level: t('boostTab.howItWorks.lvl', { level: 4 }), coins: 7, price: 16000 },
  { id: 5, level: t('boostTab.howItWorks.lvl', { level: 5 }), coins: 9, price: 32000 },
  { id: 6, level: t('boostTab.howItWorks.lvl', { level: 6 }), coins: 11, price: 64000 },
  { id: 7, level: t('boostTab.howItWorks.lvl', { level: 7 }), coins: 13, price: 128000 },
  { id: 8, level: t('boostTab.howItWorks.lvl', { level: 8 }), coins: 15, price: 256000 },
  { id: 9, level: t('boostTab.howItWorks.lvl', { level: 9 }), coins: 17, price: 512000 },
  { id: 10, level: t('boostTab.howItWorks.lvl', { level: 10 }), coins: 19, price: 1024000 }
])
const energyBoost = computed(() => [
  { id: 1, level: t('boostTab.howItWorks.lvl', { level: 1 }), energy: 1000, price: 0 },
  { id: 2, level: t('boostTab.howItWorks.lvl', { level: 2 }), energy: 1500, price: 2000 },
  { id: 3, level: t('boostTab.howItWorks.lvl', { level: 3 }), energy: 2000, price: 4000 },
  { id: 4, level: t('boostTab.howItWorks.lvl', { level: 4 }), energy: 2500, price: 16000 },
  { id: 5, level: t('boostTab.howItWorks.lvl', { level: 5 }), energy: 3000, price: 32000 },
  { id: 6, level: t('boostTab.howItWorks.lvl', { level: 6 }), energy: 4000, price: 64000 },
  { id: 7, level: t('boostTab.howItWorks.lvl', { level: 7 }), energy: 5000, price: 128000 },
  { id: 8, level: t('boostTab.howItWorks.lvl', { level: 8 }), energy: 6000, price: 256000 },
  { id: 9, level: t('boostTab.howItWorks.lvl', { level: 9 }), energy: 7000, price: 512000 },
  { id: 10, level: t('boostTab.howItWorks.lvl', { level: 10 }), energy: 8000, price: 1024000 },
  { id: 11, level: t('boostTab.howItWorks.lvl', { level: 11 }), energy: 10000, price: 2048000 },
  { id: 12, level: t('boostTab.howItWorks.lvl', { level: 12 }), energy: 12000, price: 3000000 },
  { id: 13, level: t('boostTab.howItWorks.lvl', { level: 13 }), energy: 15000, price: 5000000 }
])
</script>

<template>
  <div class="how-works" v-motion-fade>
    <h1 class="how-works-title gradient-text">
      {{ t('boostTab.howItWorks.description') }}
    </h1>
    <div>
      <p class="text-title">{{ t('boostTab.howItWorks.title1') }}</p>
      <p class="text">
        {{ t('boostTab.howItWorks.text1') }}
      </p>
    </div>
    <div class="mt-9">
      <div class="bonus-container gap-3 mb-5">
        <Button reverse-bg>{{ t('boostTab.howItWorks.tableLevelTitle') }}</Button>
        <Button reverse-bg>{{ t('boostTab.howItWorks.tableTapPowerTitle') }}</Button>
        <!-- Total coins per tap -->
        <Button reverse-bg>{{ t('boostTab.howItWorks.tablePriceTitle') }}</Button>
      </div>

      <CardWithBorderGradient class="my-2" v-for="boost in tapBoosts" :key="boost.id">
        <div class="bonus-item">
          <div class="bonus-container">
            <div class="bonus-container-name">
              {{ boost.level }}
            </div>
            <div class="bonus-value">
              {{ boost.coins }}
            </div>
            <div class="bonus-value">
              <IconBalance style="width: 17px; height: 17px" />
              <div class="text-bold">{{ amountFormat(boost.price) }}</div>
            </div>
          </div>
        </div>
      </CardWithBorderGradient>
    </div>

    <!-- 2  -->
    <div class="mt-9">
      <p class="text-title">{{ t('boostTab.howItWorks.title2') }}</p>
      <p class="text">
        {{ t('boostTab.howItWorks.text2') }}
      </p>
    </div>

    <div class="mt-9">
      <div class="bonus-container gap-3 mb-5">
        <Button reverse-bg>{{ t('boostTab.howItWorks.tableLevelTitle') }}</Button>
        <Button reverse-bg>{{ t('boostTab.howItWorks.tableEnergyLimitTitle') }}</Button>
        <Button reverse-bg>{{ t('boostTab.howItWorks.tablePriceTitle') }}</Button>
      </div>

      <CardWithBorderGradient class="my-2" v-for="boost in energyBoost" :key="boost.id">
        <div class="bonus-item">
          <div class="bonus-container">
            <div class="bonus-container-name">
              {{ boost.level }}
            </div>
            <div class="bonus-value">
              {{ boost.energy }}
            </div>
            <div class="bonus-value pr-1">
              <IconBalance style="width: 17px; height: 17px" />
              <div class="text-bold">{{ amountFormat(boost.price) }}</div>
            </div>
          </div>
        </div>
      </CardWithBorderGradient>
    </div>
  </div>
</template>

<style>
.how-works {
  padding-left: 14px;
  padding-right: 14px;
}

.bonus-item {
  border-radius: 12px;
  /* border: 1px solid #29371c; */
  background: #314222;
  padding: 10px 0;
  display: flex;
  width: 100%;
  align-items: center;
}
.bonus-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  text-transform: capitalize;
}
.how-works-title {
  text-align: center;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
  max-width: 96%;
  margin: 0 auto 64px;
}
.bonus-container-name,
.bonus-value {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  line-height: 20px; /* 153.846% */
}
.bonus-container-name {
  padding-left: 18px;
  justify-self: self-start;
  word-break: break-word;
}
.bonus-value {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: normal;
  letter-spacing: 0.065px;
}
.text,
.text-title {
  color: #fff;
  font-family: Urbanist;
  font-style: normal;
  line-height: 100%; /* 14px */
}
.text {
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
}
.text-title {
  font-size: 16px;
  font-weight: 500;
}
</style>
