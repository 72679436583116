<script setup lang="ts">
import { useUserBalanceStore } from '@/stores/userBalance'
import { usePassiveIncomeStore } from '@/stores/userPassiveIncome'
import WebApp from '@twa-dev/sdk'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './common/Button.vue'
import SpecialOfferBanner from './common/SpecialOfferBanner.vue'
import ThreeRowsCard from './common/ThreeRowsCard.vue'
import IconBalance from './icons/IconBalance.vue'
import IconStar from './icons/IconStar.vue'

const TAB_FOR_STARS = 'passiveIncome.forStars'
const TAB_FOR_COINS = 'passiveIncome.forCoins'
const TAB_FOR_VIDEO = 'passiveIncome.forVideo'

const activeTab = ref(TAB_FOR_STARS)

const { t } = useI18n()

const passiveIncome = usePassiveIncomeStore()
const { passiveIncomes, passiveIncomesForCoins } = storeToRefs(passiveIncome)
const {
  getUserTotalPassiveIncome,
  getPassiveIncomes,
  getPassiveIncomesForCoins,
  buyPassiveIncomeForCoins
} = passiveIncome

const userBalanceStore = useUserBalanceStore()
const { balance } = storeToRefs(userBalanceStore)

const isStarsTab = computed(() => activeTab.value == TAB_FOR_STARS)
const isCoinsTab = computed(() => activeTab.value == TAB_FOR_COINS)
const isVideosTab = computed(() => activeTab.value == TAB_FOR_VIDEO)

const hasSpecialOffer = computed(() => {
  switch (activeTab.value) {
    case TAB_FOR_STARS:
      return passiveIncomes.value.some((el) => el.special_cost)

    // case TAB_FOR_COINS:
    //   return passiveIncomesForCoins.value.some((el) => el.special_cost)

    default:
      return false
  }
})

const openPassiveIncomeInvoice = (invoiceLink: string) => {
  WebApp.openInvoice(invoiceLink, (status) => {
    if (status === 'paid') {
      setTimeout(() => {
        getUserTotalPassiveIncome()
      }, 5000)
    }
  })
}

const payByCoins = async (passiveIncomeId: number) => {
  try {
    await buyPassiveIncomeForCoins(passiveIncomeId)
  } catch {
    //
  }
}

onMounted(() => {
  Promise.all([getPassiveIncomes(), getPassiveIncomesForCoins()])
})
</script>

<template>
  <div class="">
    <div class="passive-income-tabs text-primary">
      <Button @click="activeTab = TAB_FOR_STARS" :active="isStarsTab">
        {{ t('passiveIncome.forStars') }}
        <template #prependIcon>
          <IconStar style="height: 18px; width: 18px" class="text-primary" />
        </template>
      </Button>
      <Button @click="activeTab = TAB_FOR_COINS" :active="isCoinsTab">
        {{ t('passiveIncome.forCoins') }}
        <template #prependIcon>
          <IconBalance style="height: 18px; width: 18px" />
        </template>
      </Button>
      <!-- <Button @click="activeTab = TAB_FOR_VIDEO" :active="isVideosTab">
        {{ t('passiveIncome.forVideo') }}
        <template #prependIcon>
          <IconChevronRight style="height: 18px; width: 18px" />
        </template>
      </Button> -->
    </div>

    <!-- specialOffer  -->

    <SpecialOfferBanner v-if="hasSpecialOffer" />

    <!-- For Stars  -->
    <div v-if="isStarsTab" class="passive-income-grid">
      <ThreeRowsCard
        v-for="income in passiveIncomes"
        :key="income.id"
        :title="`${income.coins_per_hour}/${t('common.hr')}`"
        :cost="income.stars_cost"
        :discount="income.special_cost"
        :disable="false"
        @click="openPassiveIncomeInvoice(income.invoice_link)"
      />
    </div>

    <!-- For Coins  -->
    <div v-if="isCoinsTab" class="passive-income-grid">
      <ThreeRowsCard
        v-for="income in passiveIncomesForCoins"
        :key="income.id"
        :title="`${income.coins_per_hour}/${t('common.hr')}`"
        :cost="income.coins_cost"
        :disable="balance < income.coins_cost"
        @click="payByCoins(income.id)"
      >
        <template #valueIcon>
          <IconBalance style="width: 16px; height: 16px" />
        </template>
      </ThreeRowsCard>
    </div>

    <!-- For Videos  -->
    <!-- <div v-if="isVideosTab" style="padding-top: 20px; padding-bottom: 20px">
      {{ t('common.comingSoon') }}...
    </div> -->
  </div>
</template>

<style scoped>
.passive-income-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.passive-income-tabs {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.passive-income-tab {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  padding: 4px 14px;
  color: var(--app-text-mute);
  background: var(--app-background-dark);
  border: 1px solid var(--app-background-light);
  border-radius: 18px;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  font-size: 12px;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
}
.passive-income-tab svg {
  opacity: 0.5;
}
.passive-income-tab.active {
  color: var(--app-primary);
  border: 1px solid var(--app-primary);
}
.passive-income-tab.active svg {
  opacity: 1;
}

@media (min-width: 410px) {
  .passive-income-tabs {
    gap: 16px;
  }
  .passive-income-tab {
    gap: 4px;
    padding: 4px 14px;
    font-size: 14px;
  }
}
</style>
