import { getCurrentUserRank as getUserRank, useApi } from '@/application/services/useApi'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useAuthStore } from './auth'

interface IUserByRank {
  id: number
  name: string
  credits: number
  profile_image_url: string | null
}
interface IRank {
  id: number
  name: string
  minimum_credits: number
  limit: number
}

export const useUserRankStore = defineStore('userRank', () => {
  const rank = ref<number>(0)
  const ranks = ref<IRank[]>([])
  const usersByRank = ref<IUserByRank[]>([])

  const { user } = storeToRefs(useAuthStore())

  async function getRanks() {
    try {
      const res = (await useApi().get('/api/ranks'))?.data?.data || []

      ranks.value = res.map((el: IRank, ind: number) => ({
        ...el,
        limit: res[ind + 1]?.minimum_credits || null
      }))

      return ranks.value
    } catch (error) {
      console.error('Error getting rank', error)
      // const res: any = [
      //   {
      //     id: 1,
      //     name: 'Stone League',
      //     minimum_credits: 0
      //   },
      //   {
      //     id: 2,
      //     name: 'Bronze League',
      //     minimum_credits: 2000
      //   },
      //   {
      //     id: 3,
      //     name: 'Silver League',
      //     minimum_credits: 5000
      //   },
      //   {
      //     id: 4,
      //     name: 'Gold League',
      //     minimum_credits: 50000
      //   },
      //   {
      //     id: 5,
      //     name: 'Platinum League',
      //     minimum_credits: 1000000
      //   },
      //   {
      //     id: 6,
      //     name: 'Diamond League',
      //     minimum_credits: 10000000
      //   },
      //   {
      //     id: 7,
      //     name: 'Cosmos League',
      //     minimum_credits: 50000000
      //   },
      //   {
      //     id: 8,
      //     name: 'Universe League',
      //     minimum_credits: 100000000
      //   }
      // ]

      // ranks.value = res.map((el: IRank, ind: number) => ({
      //   ...el,
      //   limit: res[ind + 1]?.minimum_credits || null
      // }))
    }
  }

  async function getUsersByRank(rank: number) {
    try {
      const res = await useApi().get(`/api/users-by-ranks/${rank}`)
      usersByRank.value = res.data.data // || []
    } catch (error) {
      console.error('Error getting rank', error)
    }
  }

  const currentRankId = computed(() => {
    return user.value?.rank_id || 1
  })

  const nextRank = computed(() => {
    const currentRankIndex = ranks.value.findIndex((el) => el.id === currentRankId.value)

    return ranks.value[currentRankIndex + 1] || ranks.value[currentRankIndex]
  })

  const currentRank = computed(
    () => ranks.value.find((el) => el.id === currentRankId.value) || ranks.value[0]
  )

  const currentRankLimit = computed(() => currentRank.value?.limit || 0)

  const rankCount = computed(() => ranks.value.length || 1)

  const rankNameById = (id: number) => {
    if (!id) return 'Bronze'

    const rank = ranks.value.filter((rank) => rank.id == id)

    return rank?.length ? rank[0].name : ''
  }

  const currentUserRank = ref<App.DTO.Rank.UserRankData | null>(null)

  function getCurrentUserRank() {
    getUserRank().then((res) => {
      currentUserRank.value = res
    })
  }

  return {
    rank,
    usersByRank,
    getRanks,
    getUsersByRank,
    ranks,
    currentRankId,
    currentRank,
    currentRankLimit,
    rankCount,
    nextRank,
    rankNameById,
    currentUserRank,
    getCurrentUserRank
  }
})
