import { notify } from '@/stores/message'
import { useTonConnect } from '@/stores/tonConnect'
import { Address } from '@ton/ton'
import { THEME, TonConnectUI, type ConnectedWallet } from '@tonconnect/ui'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export function useTonConnectUi() {
  const friendlyWalletAddress = ref('')
  const isUserWalletConnected = ref(false)

  const { tonConnectUI } = storeToRefs(useTonConnect())

  let unsubscribeOnStatusChange

  async function tonConnectUiInit() {
    if (tonConnectUI.value !== null) {
      return
    }

    const baseURL = import.meta.env.VITE_APP_BASE_URL

    tonConnectUI.value = new TonConnectUI({
      manifestUrl: `${baseURL}/tonconnect-manifest.json`
      //   buttonRootId: 'connect-wallet-button'
    })

    tonConnectUI.value.uiOptions = {
      language: 'en',
      uiPreferences: {
        theme: THEME.DARK
      }
      // actionsConfiguration: {
      //   twaReturnUrl: baseURL
      // }
    }
  }

  function registerOnStatusChange(callback: (wallet: ConnectedWallet | null) => void) {
    if (!tonConnectUI.value) {
      return
    }

    unsubscribeOnStatusChange = tonConnectUI.value.onStatusChange(callback)
  }

  function transformAddressFromRawToFriendly(address: string) {
    return (friendlyWalletAddress.value = Address.parseRaw(address).toString())
  }

  async function modalOpen() {
    if (!tonConnectUI.value) {
      await tonConnectUiInit()
    }

    tonConnectUI.value?.modal?.open()
  }

  async function checkIsUserWalletConnected() {
    await tonConnectUI.value?.connectionRestored

    return (isUserWalletConnected.value = tonConnectUI.value?.wallet !== null)
  }

  async function disconnectWallet() {
    if (!tonConnectUI.value) {
      console.error('TonConnectUI is not initialized')
      return
    }

    await tonConnectUI.value.connectionRestored

    if (tonConnectUI.value.connected) {
      tonConnectUI.value.disconnect().then(() => {
        notify('Wallet disconnected successfully')
      })
    }
  }

  function onTransactionSigned(callback: (transaction: any) => void) {
    window.addEventListener('ton-connect-ui-transaction-signed', async (event: any) => {
      await callback(event.detail)
    })
  }

  return {
    tonConnectUiInit,
    transformAddressFromRawToFriendly,
    modalOpen,
    registerOnStatusChange,
    unsubscribeOnStatusChange,
    checkIsUserWalletConnected,
    disconnectWallet,
    isUserWalletConnected,
    onTransactionSigned
  }
}
