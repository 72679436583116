<script setup lang="ts">
import {
  useTournamentStore,
  type Tournament as TournamentCategory,
  type TournamentCurrencyRound
} from '@/stores/tournament'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ListItem from '../common/ListItem.vue'
import IconCheck from '../icons/IconCheck.vue'
import IconCupSolid from '../icons/IconCupSolid.vue'
import IconMore from '../icons/IconMore.vue'
import TournamentBet from './TournamentBet.vue'

const currencyToPair = 'usdt'

defineProps<{ showTabs: boolean }>()
const emits = defineEmits(['update:show-tabs'])

import Tournament from './Tournament.vue'

const selectedCategoryId = ref<number | null>(null)
const storedSelectedCategoryId = ref<number | null>(null)

const { t } = useI18n()

const tournamentStore = useTournamentStore()
const { getTournamentCategories } = tournamentStore
const { tournamentCategories, selectedCurrencyRound, selectedCategory } =
  storeToRefs(tournamentStore)

onMounted(async () => {
  try {
    await getTournamentCategories()
  } catch (e) {
    //
  }
})

const selectCategory = (tournament: TournamentCategory) => {
  if (tournament.attemptsMax - tournament.attemptsMade <= 0) return

  selectedCategoryId.value = tournament.id
  selectedCategory.value = tournament
  emits('update:show-tabs', false)
}
const closeCategory = () => {
  selectedCategoryId.value = null
  emits('update:show-tabs', true)
}

async function registeredForTournament() {
  closeCategory()
  await getTournamentCategories()
}

const selectRound = (tournament: TournamentCurrencyRound) => {
  selectedCurrencyRound.value = tournament
  storedSelectedCategoryId.value = selectedCategoryId.value
  selectedCategoryId.value = null
}
const closeTheRound = () => {
  selectedCurrencyRound.value = null
  selectedCategoryId.value = storedSelectedCategoryId.value
  storedSelectedCategoryId.value = null
}
</script>

<template>
  <div>
    <div v-if="!selectedCategoryId && !selectedCurrencyRound" class="">
      <h2 class="mt-4 page-title" v-html="t('tournamentTab.tournaments.title')"></h2>

      <div style="max-width: 77%" class="ma-auto">
        <h3 class="page-sub-title mt-5" v-html="t('tournamentTab.tournaments.description')"></h3>
      </div>

      <!-- categories list  -->
      <div v-if="tournamentCategories?.length > 0" class="list-container mt-8">
        <ListItem
          v-for="category in tournamentCategories"
          :key="category.id"
          :title="category.title"
          @click.stop="selectCategory(category)"
        >
          <template #prepend>
            <IconCupSolid
              class="text-dark-green"
              style="width: 48px; height: 48px; max-width: 48px"
            />
          </template>
          <template #subtitle>
            <div class="subtitle">
              <div class="">
                {{
                  `${t('tournamentTab.tournaments.attemptsLeftForToday')} ${category.attemptsMax - category.attemptsMade}/${category.attemptsMax}`
                }}
              </div>
              <div class="">
                {{ t('tournamentTab.tournaments.categories.pointsAchievedToday') }}
                {{ category.totalScore }}
              </div>
            </div>
          </template>
          <template #append>
            <IconCheck v-if="false" style="width: 25px; height: 25px" />
            <IconMore v-else style="width: 21px; height: 21px; stroke-width: 2px" />
          </template>
        </ListItem>
      </div>
      <ListItem v-else class="mt-8">
        {{ t('tournamentTab.tournaments.dontHaveCategories') }}
      </ListItem>
    </div>

    <!-- category and rounds list in category -->
    <Tournament
      v-if="selectedCategoryId"
      :categoryId="selectedCategoryId"
      @close="closeCategory"
      @select-round="selectRound"
      @registered="registeredForTournament"
    />

    <!-- tournament betting  -->
    <TournamentBet
      v-if="selectedCurrencyRound"
      :currencyToPair="currencyToPair"
      @registered="closeTheRound"
      @close="closeTheRound"
    />
  </div>
</template>

<style scoped>
.subtitle {
  margin-top: 8px;
  font-size: 12px;
  letter-spacing: -0.05rem;
  line-height: 1.2;
  /* color: var(--app-gray); */
}
</style>
